$( document ).ready(function() {
    $("a.smooth-scroll").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 82
            }, 800, function () {
                // Add hash (#) to URL when done scrolling (default click behavior)
                //window.location.hash = hash;
            });
        } 
    });

    $(".smooth-scroll a").on('click', function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - 82
            }, 800, function () {
                // Add hash (#) to URL when done scrolling (default click behavior)
                //window.location.hash = hash;
            });
        }
    });

    var hamburger = document.querySelector(".hamburger");
    hamburger.addEventListener("click", function() {
        hamburger.classList.toggle("is-active");
        $('.menu-mobile').toggleClass('menu-mobile-active');
        if( $('.menu-mobile').hasClass('menu-mobile-active') ){
            $('.shadow-menu').css({display: "block"})
           
        }else{
            $('.shadow-menu').css({display: "none"})
            
        }
    });

    $('input.phone').mask('(99) 99999-9999');
    $('.forminator-phone--field').mask('(99) 99999-9999');

    new WOW().init();

    /* $('.services-form').bind('DOMNodeInserted', function() {
        console.log('loaded')
    }); */

    function checkContainer () {
        if(!$('.services-form #forminator-submit').is(':visible')){
            setTimeout(checkContainer, 50);
        } else {
            console.log('done');
            $('.forminator-phone--field').mask('(99) 99999-9999');
            $('.services-form  .forminator-row').first().addClass('justify-content-center').append('<div id="btn-submit" class="forminator-col d-flex align-self-center" style="height:95px">' + $('#submit').html() + '</div>');
            $('.services-form #submit').remove();
            $('.services-form .forminator-row .forminator-col').removeClass('forminator-col-3');
            $('#btn-submit').addClass('align-self-center');
            $('.services-form .forminator-row .forminator-col .forminator-field').each(function(){
                $(this).addClass('width-fix');
            })
        }
      }
      checkContainer();


    var input_menu = $( ".search-input-menu" )
    input_menu.focus(function() {
        $('.item-spotlight:nth-child(1)').addClass('item-spotligh-active')
        $('.ic-lupa').addClass('ic-lupa-active')
    })
    .focusout(function() {
        $('.item-spotlight:nth-child(1)').removeClass('item-spotligh-active')
        $('.ic-lupa').removeClass('ic-lupa-active')
    })

    $('.sub-menu-link').hover(function() {
        $('.sub-menu').toggleClass('d-block')
    });
  

})